import $ from 'jquery';
window.$ = $;
import '@popperjs/core';
import '@selectize/selectize';
import * as _bootstrap from 'bootstrap';
import '../javascripts/bootstrap/search/region-selector';
import '../javascripts/ga/google_analytics4';
import '../javascripts/bootstrap/warning';
import '../javascripts/offer';
import '../javascripts/bootstrap/site-notice';
