import * as _bootstrap from 'bootstrap';

$(function() {
  if (window.location.href.indexOf('validate_card') !== -1) {
    const el = document.getElementById('verify-card-modal');
    const modal = _bootstrap.Modal.getOrCreateInstance(el);
    modal.show();
  }

  $('.js-trigger-set-location').click(function(e) {
    e.preventDefault();
  });
});