import Cookies from 'js-cookie';

const siteNotices = document.querySelectorAll('.alert');

function handleSiteNoticeClose(event) {
  const siteNoticeId = event.target.getAttribute('data-id');
  
  if(Cookies.get('siteNotices')) {
    const siteNotices = JSON.parse(Cookies.get('siteNotices'));
    siteNotices.ids.push(siteNoticeId);
    Cookies.set('siteNotices', JSON.stringify(siteNotices));
  } else {
    const siteNotices = {
      ids: [siteNoticeId],
    };
    Cookies.set('siteNotices', JSON.stringify(siteNotices));
  }
}

siteNotices.forEach(siteNotice => {
  siteNotice.addEventListener('closed.bs.alert', handleSiteNoticeClose);
});